<template>
  <b-container class="settings-wizards bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">{{ $t(translationPath + 'title') }}</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="step-container">
            <div class="stepper bg-light mx-auto text-regular">
              <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#525670'"  :errorColor="'#525670'" ref="step" :nextButtonText="$t('common.next')" :backButtonText="$t('common.previous')" :finishButtonText="$t('common.finalize_the_import')" @on-complete="onComplete">
                <tab-content :title="$t(translationPath + 'step1.label')" :before-change="() => validateStep('step1')">
                  <wizard-step-01 ref="step1" @on-validate="(values) => updateUserCoa(values, 'step1')"></wizard-step-01>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step2.label')" :before-change="() => validateStep('step2')">
                  <wizard-step-02 ref="step2" :list="coaList"  @on-validate="(values) => updateUserCoa(values, 'step2')"></wizard-step-02>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step3.label')" :before-change="() => validateStep('step3')">
                  <wizard-step-03 ref="step3" @on-validate="(values) => updateUserCoa(values, 'step3')"></wizard-step-03>
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                    <div class=wizard-footer-left>
                      <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                        {{ $t('common.previous') }}
                      </wizard-button>
                    </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="finishDisabled">
                      {{ $t('common.finalize_the_import') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
              </form-wizard>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--success modal-->
    <success-modal
      :title="$t(translationPath + 'modal_title')"
      :message="$t(translationPath + 'modal_success_message')"
      :button="$t(translationPath + 'modal_button_primary')"
      redirect="/incomes-expenses/accounts/income"
      @hide="$router.push('/incomes-expenses/accounts/income')"
    />
  </b-container>
</template>

<script>
import SuccessModal from '@/components/modals/SuccessModal'
import WizardStep01 from './wizard-steps/WizardStep01'
import WizardStep02 from './wizard-steps/WizardStep02'
import WizardStep03 from './wizard-steps/WizardStep03'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import axios from 'axios'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
export default {
  name: 'BalansBladeImport',
  components: { FormWizard, TabContent, WizardStep01, WizardStep02, WizardStep03, SuccessModal, WizardButton },
  data () {
    return {
      username: null,
      password: null,
      coaList: [],
      userId: null,
      coaId: null,
      importValues: [],
      finishDisabled: false,
      translationPath: 'settings.balansbladet_import.'
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('settings.submenu.settings_title'), to: '#' },
        { text: this.$t('settings.submenu.balansbladet_import'), to: '#' },
        { text: '', active: true }
      ]
    }
  },
  methods: {
    validateStep (name) {
      return this.$refs[name].validate()
    },
    updateUserCoa (value, step) {
      if (step === 'step1') {
        this.username = value.user
        this.password = value.pass
        this.userId = value.user_id
        this.coaList = value.coa_list
      } else if (step === 'step2') {
        this.coaId = value.coa_id
      } else {
        this.importValues = {}
        for (const prop in value.selectedCategory) {
          this.importValues[value.selectedCategory[prop]] = 1
        }
      }
    },
    resetAll () {
      this.$refs.step1.reset()
      this.$refs.step2.reset()
      this.$refs.step3.reset()
    },
    onComplete () {
      this.finishDisabled = true
      this.$bvToast.toast(this.$t(this.translationPath + 'toast.import_processed'), {
        title: this.$t(this.translationPath + 'title'),
        variant: 'success',
        solid: true,
        'auto-hide-delay': 5000
      })
      axios.post(`${process.env.VUE_APP_ROOT_API}/import-database`, {
        username: this.username,
        password: this.password,
        coa_id: this.coaId,
        import: this.importValues
      })
        .then(response => response.data.data)
        .then(responseData => {
          this.$refs.step.reset()
          this.finishDisabled = false
          this.resetAll()
          this.$bvModal.show('successModal')
        })
        .catch(err => {
          this.finishDisabled = false
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.import_failed'), {
            title: this.$t(this.translationPath + 'title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard.scss';
</style>
