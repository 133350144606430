<template>
    <div class="SettingImportStep01">
        <b-container class="import-wizard-step01">
          <b-row no-gutters>
            <b-col sm="12" md="4" lg="5" class="detail-text text-left pt-4 pb-2 px-0 py-md-5 pl-md-0 pr-md-3 py-lg-5 px-lg-5" align-self="center">
               <h2 class="text-dark">{{$t(translationPath + 'heading')}}</h2>
               <p class="text-gray mb-0">
                 {{$t(translationPath + 'intro_text')}}
               </p>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="accounts_wrapper py-2 mb-3 mb-md-0 px-0 py-md-5 pl-md-5 pr-md-0 py-lg-5 px-lg-5">
              <div class="errorDiv">
                <b-alert :show="!validUsernamePassword" variant="danger" class="text-center mb-4">{{ $t(translationPath + 'form.errors.username_password_valid') }}</b-alert>
              </div>
              <b-form-group
                label-class="pr-4 text-black FormLabels"
                label-for="username"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.username')"
                :state="usernameState"
                :invalidFeedback="invalidUsernameFeed"
              >
                <template slot="description">{{ $t(translationPath + 'form.descriptions.username') }}</template>
                <b-form-input
                  v-model.trim="previousUsername"
                  :state="usernameState"
                  :placeholder="$t(translationPath + 'form.placeholders.password')"
                  autocomplete="off"
                  @input="$v.previousUsername.$touch()"
                  @change="resetValidUser"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-class="pr-4 text-black FormLabels"
                label-for="password"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.password')"
                :state="passwordState"
                :invalidFeedback="invalidPasswordFeed"
              >
                <template slot="description">{{ $t(translationPath + 'form.descriptions.password') }}</template>
                <b-form-input
                  v-model.trim="previousPassword"
                  :state="passwordState"
                  type="password"
                  autocomplete="off"
                  :placeholder="$t(translationPath + 'form.placeholders.password')"
                  @input="handleTouch('password')"
                  @change="resetValidUser"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'
const validUserPass = function () {
  return this.validUsernamePassword
}
export default {
  name: 'WizardStep01',
  data () {
    return {
      previousUsername: null,
      previousPassword: null,
      validUsernamePassword: true,
      translationPath: 'settings.balansbladet_import.step1.'
    }
  },
  validations: {
    previousUsername: {
      required,
      minLength: minLength(2),
      validUser: validUserPass
    },
    previousPassword: {
      required,
      minLength: minLength(2),
      validPass: validUserPass
    },
    form: ['previousUsername', 'previousPassword']
  },
  computed: {
    usernameState () {
      return this.$v.previousUsername.$dirty ? !this.$v.previousUsername.$error : null
    },
    passwordState () {
      return this.$v.previousPassword.$dirty ? !this.$v.previousPassword.$error : null
    },
    invalidUsernameFeed () {
      if (!this.$v.previousUsername.required) {
        return this.$t(this.translationPath + 'form.errors.username_required')
      }
      if (!this.$v.previousUsername.minLength) {
        return this.$t(this.translationPath + 'form.errors.username_minlength')
      }
      return ''
    },
    invalidPasswordFeed () {
      if (!this.$v.previousPassword.required) {
        return this.$t(this.translationPath + 'form.errors.password_required')
      }
      if (!this.$v.previousPassword.minLength) {
        return this.$t(this.translationPath + 'form.errors.password_minlength')
      }
      return ''
    }
  },
  methods: {
    resetValidUser () {
      if (!this.validUsernamePassword) {
        this.validUsernamePassword = true
      }
    },
    handleTouch (type) {
      if (type === 'password') {
        this.$v.previousPassword.$touch()
      }
      this.$v.previousUsername.$touch()
    },
    reset () {
      this.validUsernamePassword = true
      this.previousUsername = null
      this.previousPassword = null
      this.$v.form.$reset()
    },
    validate () {
      this.validUsernamePassword = true
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        return axios.post(`${process.env.VUE_APP_ROOT_API}/import-database/get-coas`, { username: this.previousUsername, password: this.previousPassword })
          .then(response => response.data.data)
          .then(responseData => {
            const coaLists = responseData.coa_list || []
            const traverseLists = coaLists.map(item => {
              return Object.assign(item, {
                text: item.name,
                value: item.financial_sheet_id
              })
            })
            this.validUsernamePassword = true
            this.$emit('on-validate', {
              user_id: responseData.user_id || null,
              coa_list: traverseLists,
              user: this.previousUsername,
              pass: this.previousPassword
            })
            return true
          })
          .catch(err => {
            const errorStatusCode = err.response.status
            this.validUsernamePassword = errorStatusCode !== 403
            return false
          })
      } else {
        return !this.$v.form.$invalid
      }
    }
  }
}
</script>

<style lang="scss">
.SettingImportStep01 {
  .FormLabels {
    padding-top: 14px;
  }
  .errorDiv {
    width: 100%;
  }
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left: none;
    }
  }
}
</style>
