<template>
    <div class="SettingImportStep02">
        <b-container class="import-wizard-step01">
          <b-row no-gutters>
            <b-col sm="12" md="4" lg="5" class="detail-text text-left pt-4 pb-2 px-0 py-md-5 pl-md-0 pr-md-3 py-lg-5 px-lg-5" align-self="center">
               <h2 class="text-dark">{{$t(translationPath + 'heading')}}</h2>
               <p class="text-gray mb-0">
                 {{$t(translationPath + 'intro_text')}}
               </p>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="accounts_wrapper py-2 mb-3 mb-md-0 px-0 py-md-5 pl-md-5 pr-md-0 py-lg-5 px-lg-5">
                <b-form-group
                  label-class="pr-4 text-black FormLabels pl-1"
                  :label="$t(translationPath + 'form.labels.chart_of_account')"
                  label-cols-lg="4"
                  label-align-lg="right"
                  :state="coaState"
                  :invalidFeedback="coaFeedback"
                >
                  <b-form-select
                    id="offsetaccount"
                    v-model="coa"
                    :state="coaState"
                    :options="list"
                  >
                    <template v-slot:first>
                      <option :value="null" disabled>{{ $t(translationPath + 'form.placeholders.chart_of_account')}}</option>
                    </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'WizardStep02',
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      translationPath: 'settings.balansbladet_import.step2.',
      coa: null
    }
  },
  validations: {
    coa: {
      required
    }
  },
  computed: {
    coaFeedback () {
      if (!this.$v.coa.required) {
        return this.$t(this.translationPath + 'form.errors.coa_required')
      }
      return ''
    },
    coaState () {
      return this.$v.$dirty ? !this.$v.$error : null
    }
  },
  methods: {
    reset () {
      this.coa = true
      this.$v.$reset()
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('on-validate', {
          coa_id: this.coa
        })
      }
      return !this.$v.$invalid
    }
  }
}
</script>

<style lang="scss">
.SettingImportStep02 {
  .FormLabels {
    align-self: center;
  }
  .confirm_text {
    font-size: 20px;
    font-weight: 100;
  }
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left: none;
    }
  }
}
</style>
