<template>
    <div class="SettingImportStep03">
        <b-container class="import-wizard-step01">
          <b-row no-gutters>
            <b-col sm="12" md="4" lg="5" class="detail-text text-left pt-4 pb-2 px-0 py-md-5 pl-md-0 pr-md-3 py-lg-5 px-lg-5" align-self="center">
               <h2 class="text-dark">{{$t(translationPath + 'heading')}}</h2>
               <p class="text-gray mb-0">
                 {{$t(translationPath + 'intro_text')}}
               </p>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="accounts_wrapper py-2 px-0 py-md-5 pl-md-5 pr-md-0 py-lg-5 px-lg-5">
                <b-form-group
                  label-class="FormLabels pl-1"
                  :label="$t(translationPath + 'form.labels.import')"
                  label-cols-lg="3"
                  :state="importState"
                  :invalidFeedback="importFeedback"
                >
                  <b-form-checkbox-group
                    v-model="importCategory"
                    :state="importState"
                    name="importOptions"
                    stacked
                  >
                    <b-form-checkbox v-for="(item, keys) in importOptions" v-bind:key="keys" v-bind:value="item.value" :disabled="item.value === 'transactions' || item.value === 'budget_amounts' ? disabledOptions : false">
                      {{ item.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
const selectedGroup = function () {
  return this.importCategory.length > 0
}
export default {
  name: 'WizardStep03',
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  validations: {
    importCategory: {
      valid: selectedGroup
    }
  },
  data () {
    return {
      translationPath: 'settings.balansbladet_import.step3.',
      importCategory: ['income_accounts', 'expense_accounts', 'asset_accounts', 'liability_accounts', 'transactions', 'budget_amounts']
    }
  },
  computed: {
    importFeedback () {
      if (!this.$v.importCategory.valid) {
        return this.$t(this.translationPath + 'form.errors.import_required')
      }
      return ''
    },
    importState () {
      return this.$v.$dirty ? !this.$v.$error : null
    },
    importOptions () {
      return [
        { text: this.$t(this.translationPath + 'import_types.income_accounts'), value: 'income_accounts' },
        { text: this.$t(this.translationPath + 'import_types.expense_accounts'), value: 'expense_accounts' },
        { text: this.$t(this.translationPath + 'import_types.asset_accounts'), value: 'asset_accounts' },
        { text: this.$t(this.translationPath + 'import_types.liability_accounts'), value: 'liability_accounts' },
        { text: this.$t(this.translationPath + 'import_types.transactions'), value: 'transactions' },
        { text: this.$t(this.translationPath + 'import_types.budget_amounts'), value: 'budget_amounts' }
      ]
    },
    disabledOptions () {
      if (this.importCategory.indexOf('income_accounts') > -1) {
        return false
      }
      if (this.importCategory.indexOf('expense_accounts') > -1) {
        return false
      }
      return true
    }
  },
  methods: {
    reset () {
      this.importCategory = ['income_accounts', 'expense_accounts', 'asset_accounts', 'liability_accounts', 'transactions', 'budget_amounts']
      this.$v.$reset()
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('on-validate', {
          selectedCategory: this.importCategory
        })
      }
      return !this.$v.$invalid
    }
  },
  watch: {
    importCategory: function (newVal) {
      if (newVal.length > 0) {
        if (newVal.indexOf('transactions') > -1 || newVal.indexOf('budget_amounts') > -1) {
          const incomeExpenseExists = newVal.indexOf('income_accounts') > -1 || newVal.indexOf('expense_accounts') > -1
          if (!incomeExpenseExists && newVal.indexOf('transactions') > -1) {
            this.importCategory.splice(newVal.indexOf('transactions'), 1)
          } else if (!incomeExpenseExists && newVal.indexOf('budget_amounts') > -1) {
            this.importCategory.splice(newVal.indexOf('budget_amounts'), 1)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.SettingImportStep03 {
  .FormLabels {
    padding-left: 0;
    font-weight: 500;
    padding-top: 5px;
    padding-right: 12px;
  }
  .custom-control-label::before {
    top:7px;
  }
  .custom-control-label::after {
    top: 7px;
  }
  .custom-control-label {
    font-size: 14px;
    line-height: 29px;
    font-weight: 100;
  }
  .confirm_text {
    font-size: 20px;
    font-weight: 100;
  }
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left: none;
    }
  }
}
</style>
